import { BrowserRouter, Routes, Route } from "react-router-dom";
import {Landing} from "./pages/Landing"

export const App: React.FC = () => {
  return (
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Landing />} />
        </Routes>
      </BrowserRouter>
  );
};
