import {Text, VStack} from "@chakra-ui/react";
import {COLORS} from "../../core/constants";


export default function About() {


    return (
        <VStack bg={COLORS.LIGHT_GREY} id={"about"}>
            <Text color={COLORS.BLACK_FONT} fontSize={{base: "3xl", md: "4xl"}} mt={8}>About Me</Text>
            <Text color={COLORS.ORANGE} fontSize={{base: "4xl", md: "5xl"}}>Abdulelah Alharabi</Text>
            <Text paddingLeft={"7%"} paddingRight={"7%"} mb={12} mt={1}
                  fontSize={{base: "md", md:"lg"}} align={'justify'} maxWidth={"80rem"}>As a seasoned Software Engineer with a profound passion for the ever-evolving software industry,
                I embarked on my journey in software development back in 2016. Nurturing my enthusiasm,
                I pursued higher education, commencing university in 2018 and proudly graduating in 2023.
                My commitment to staying at the forefront of technology is evident in my accomplishments,
                including a Deep Learning certificate from DeepLearning.ai earned in 2022 and the Google Cloud Digital Leader
                certificate acquired in 2023. Despite my expertise in deep learning, my heart lies in the realm of
                software development, where I find joy and fulfillment in crafting innovative solutions.
                My journey is a testament to my dedication to continuous learning and my unwavering passion
                for contributing to the dynamic landscape of technology.</Text>
        </VStack>
    )
}