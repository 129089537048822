import {Image, Text, VStack} from "@chakra-ui/react";
import {COLORS} from "../../core/constants";


export default function Skills() {

    return (
        <VStack bg={COLORS.DARK_BLUE} justifyContent={"left"} paddingLeft={"7%"} paddingRight={"7%"}>
            <Image src={"/images/orange_bubbles.png"} height={58 * 0.7} width={85 * 0.7}
                   position={"absolute"} marginLeft={{base:"75%", md: "80%"}} mt={"2rem"}/>
                <Text color={COLORS.WHITE} fontSize={{base: "2xl", md:"3xl"}} mt={"2rem"}>Professional Skills</Text>

            <Text color={COLORS.ORANGE} fontSize={{base: "2xl", md: "4xl"}}>Development Environments</Text>
            <VStack spacing={"1.5rem"} mt={4} maxWidth={"80rem"} fontSize={"lg"} alignItems={"flex-start"}>
                <Text color={COLORS.WHITE}>iOS Development</Text>
                <Text color={COLORS.WHITE}>FullStack Development</Text>
                <Text color={COLORS.WHITE}>FrontEnd Development</Text>
                <Text color={COLORS.WHITE}>Android Development</Text>
                <Text color={COLORS.WHITE}>BackEnd Development</Text>
            </VStack>

            <Text color={COLORS.WHITE} fontSize={{base: "2xl", md: "3xl"}} mt={8}>Tools</Text>
            <Text paddingLeft={"7%"} paddingRight={"7%"} mb={12} mt={1} fontSize={{base: "md", md: "lg"}} color={COLORS.WHITE}>
                React, Django, Node.js, Google Cloud, xCode, Android Studio
            </Text>

        </VStack>
    )
}