import { HStack, Image, Text, VStack} from "@chakra-ui/react";
import {COLORS} from "../../core/constants";


export default function Footer() {

    return (
        <HStack bg={COLORS.DARK_BLUE} paddingLeft={"7%"} paddingRight={"7%"} spacing={"10%"} paddingTop={"2rem"}
        verticalAlign={"text-top"} paddingBottom={"2.5rem"}>
            <Text color={COLORS.WHITE} fontSize={"2xl"} verticalAlign={"top"}>Abdulelah Alharabi</Text>
            <VStack color={COLORS.WHITE} align={"left"} spacing={"1rem"}>
                <Text fontSize={"md"}><a href={"#about"}>About</a></Text>
                <Text fontSize={"md"}><a href={"#skills"}>Skills</a></Text>
                <Text fontSize={"md"}><a href={"#edu-cert"}>Education</a></Text>
                <Text fontSize={"md"}><a href={"#edu-cert"}>Certifications</a></Text>
                <Text fontSize={"md"}><a href={"#portfolio"}>Portfolio</a></Text>
            </VStack>
            <VStack mt={"-2rem"}>
                <Text color={COLORS.WHITE} fontSize={"md"}>Social Media Accounts</Text>
                <HStack>
                    <a href={"https://www.linkedin.com/in/abdulelah-alharabi-545286148"} target={"_blank"}>
                        <Image src={"/images/linkedin_white.svg"} height={30}/>
                    </a>
                </HStack>
            </VStack>
            <Image src={"/images/orange_bubbles.png"} height={58 * 0.7} width={85 * 0.7}
                   position={"absolute"} marginLeft={{base:"70%", md: "80%"}} mt={"12rem"}/>
        </HStack>
    )

}