import {
    Button,
    HStack,
    Stack,
    Text
} from '@chakra-ui/react'
import {COLORS} from "../../core/constants";

export default function Header() {

    return (
        <header>
            <HStack bg={COLORS.DARK_BLUE} height={75} color={COLORS.WHITE} justifyContent="space-between"
            paddingLeft={"5%"} paddingRight={"5%"}>
                <Text fontSize={{base: "md", md: "xl", lg: "2xl"}}><a href={"/"}>Abdulelah Alharabi</a></Text>

                <HStack spacing={"1.5rem"}>
                    <Stack display={{base: "none", lg:"flex"}} direction={"row"} spacing={"1.25rem"}>
                        <Text fontSize={{base: "sm", lg: "md"}}><a href={"#about"}>About</a></Text>
                        <Text fontSize={{base: "sm", lg: "md"}}><a href={"#skills"}>Skills</a></Text>
                        <Text fontSize={{base: "sm", lg: "md"}}><a href={"#edu-cert"}>Education</a></Text>
                        <Text fontSize={{base: "sm", lg: "md"}}><a href={"#edu-cert"}>Certifications</a></Text>
                        <Text fontSize={{base: "sm", lg: "md"}}><a href={"#portfolio"}>Portfolio</a></Text>
                    </Stack>
                    <a href={"https://storage.googleapis.com/abdulelah_website/Abdulelah_Alharabi_CV.pdf"}>
                    <Button bg={COLORS.SECONDARY} color={COLORS.WHITE} fontSize={{base: "sm", md: "md"}}
                    maxWidth={{base: "6.5rem", md: "12rem"}} _hover={{bg: COLORS.ORANGE}}>Download CV</Button>
                    </a>
                </HStack>
            </HStack>
        </header>
    )
}