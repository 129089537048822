import Header from "../components/Landing/Header";
import Hero from "../components/Landing/Hero";
import About from "../components/Landing/About";
import Skills from "../components/Landing/Skills";
import Education from "../components/Landing/Education";
import Portfolio from "../components/Landing/Portfolio";
import Contact from "../components/Landing/Contact";
import Footer from "../components/Landing/Footer";


export const Landing = () => {
    return (
        <>
            <Header/>
            <Hero/>
            <About/>
            <Skills/>
            <Education/>
            <Portfolio/>
            <Contact/>
            <Footer/>
        </>
    )
}