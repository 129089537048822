import {Button, HStack, Image, Stack, Text, Tooltip, VisuallyHidden, VStack} from "@chakra-ui/react";
import {COLORS} from "../../core/constants";


export default function Hero() {

    return (
        <VStack bg={COLORS.DARK_BLUE} paddingBottom={"1rem"} minHeight={{base: "32rem", lg: "37rem"}}
                alignItems={"flex-start"}>
                <Image src={"/images/orange_bubbles.png"} height={58 * 0.7} width={85 * 0.7} mt={"1.5rem"}
                   position={"absolute"} marginLeft={"7%"}/>
                <Image src={"/images/white_sketch.png"} height={58 * 0.7} width={85 * 0.7} mt={"1.5rem"}
                   position={"absolute"} marginLeft={{base:"80%", md: "90%"}}/>

            <Stack spacing={"10%"} justifyContent={"left"} direction={{base: "column", md:"row"}}>
                <VStack paddingLeft={{base: "15%", xl: "20%"}} alignItems={"flex-start"} paddingTop={"7rem"}>
                    <Text color={COLORS.WHITE} fontSize={{base: "3xl", lg:"4xl"}}>Hello, I'm</Text>
                    <HStack>
                        <Text color={COLORS.WHITE} fontSize={{base: "4xl", lg:"5xl"}}>Abdulelah </Text>
                        <Text color={COLORS.ORANGE} fontSize={{base: "4xl", lg:"5xl"}}>Alharabi</Text>
                    </HStack>

                    <HStack>
                        <Text color={COLORS.WHITE} fontSize={{base: "xl", lg:"2xl"}}>A passionate </Text>
                        <Text color={COLORS.ORANGE} fontSize={{base: "xl", lg:"2xl"}}>Software Engineer </Text>

                    </HStack>
                    <HStack>
                        <Text color={COLORS.WHITE} fontSize={{base: "xl", lg:"2xl"}}>from</Text>
                        <Text color={COLORS.ORANGE} fontSize={{base: "xl", lg:"2xl"}}>Saudi Arabia!</Text>
                    </HStack>

                    <HStack mt={4}>

                        <Button bg={COLORS.ORANGE} color={COLORS.WHITE} fontSize={"md"} _hover={{bg: COLORS.ORANGE}}>
                            <a href={"#contact"}>
                                Contact Me
                            </a>
                        </Button>
                        <a href={"https://www.linkedin.com/in/abdulelah-alharabi-545286148"} target={"_blank"}>
                            <Tooltip label={"LinkedIn"}>
                                <Image src={"/images/linkedin_white.svg"} height={"1.90rem"}  ml={30} alt={"LinkedIn"}/>
                            </Tooltip>
                            <VisuallyHidden>LinkedIn</VisuallyHidden>
                        </a>
                        <a href={"https://github.com/abdulelah1"} target={"_blank"}>
                            <Tooltip label={"GitHub"}>
                                <Image src={"/images/github-mark-white.svg"} height={"1.70rem"} alt={"GitHub"}/>
                            </Tooltip>
                            <VisuallyHidden>GitHub</VisuallyHidden>
                        </a>
                    </HStack>
                </VStack>
                <Image src={"/images/saudi_hero.png"} width={{base: "70%", md: "40%"}}
                       mt={{base: "3rem", lg:"5rem"}} ml={{base: "15%", md: "-7%", xl: "12%"}}/>

            </Stack>
            <Stack>
                <Image src={"/images/white_arrow.png"} height={72 * 0.7} width={72 * 0.7} mt={{base: "-2.5rem", md: "2rem"}}
                       position={"absolute"} marginLeft={"7%"}/>
                <Image src={"/images/orange_line.png"} height={65 * 0.7} width={47 * 0.7} mt={{base: "-2.5rem", md: "2rem"}}
                       position={"absolute"} marginLeft={{base: "85%", md: "90%"}}/>
            </Stack>
        </VStack>
    )
}