import {
    Center,
    HStack,
    Image,
    List,
    ListItem,
    Stack,
    Text,
    UnorderedList,
    VStack
} from "@chakra-ui/react";
import {COLORS} from "../../core/constants";
import React from "react";


export default function Education() {

    return (

            <Stack bg={COLORS.LIGHT_GREY} paddingLeft={"7%"} paddingRight={"7%"} id={"edu-cert"}>
                <Stack mb={12} direction={{base: "column", lg: "row"}} justifyContent={"center"}>
                    <VStack alignItems={"flex-start"}>
                        <HStack justifyContent={"left"} mt={12}>
                            <Stack bg={COLORS.ORANGE} height={50} width={50} borderRadius={25}>
                                <Center>
                                    <Image src={"/images/education_icon.svg"} verticalAlign={"middle"} height={35} width={35} mt={1.5}/>
                                </Center>
                            </Stack>

                            <Text fontSize={"xl"} color={COLORS.ORANGE}>
                                Education
                            </Text>
                        </HStack>

                        <HStack justifyContent={"left"} mt={4}>
                            <Stack borderRadius={25}>
                                <Center>
                                    <Image width={"4rem"}
                                           src={"/images/kfupm_logo.png"} verticalAlign={"middle"} mt={1.5}/>
                                </Center>
                            </Stack>

                            <Text fontSize={{base: "lg", md: "xl"}} color={COLORS.BLACK_FONT} mt={1.5}>
                                King Fahd University of Petroleum & Minerals
                            </Text>
                        </HStack>

                        <Text fontSize={{base: "lg", md: "xl"}} color={COLORS.BLACK_FONT} ml={57} fontWeight={"bold"}>
                            Bachelor of Science in Software Engineering
                        </Text>

                        <Text fontSize={"md"} color={COLORS.BLACK_FONT} ml={57} fontWeight={"600"}>
                            Graduated in June, 2023 <br/> Electives Taken:<br/>
                            <UnorderedList>
                                <ListItem>Secure Software Development</ListItem>
                                <ListItem>Intro. to Artificial Intelligence</ListItem>
                                <ListItem>Big Data Analytics</ListItem>
                                <ListItem>Principles of Management</ListItem>
                            </UnorderedList>
                        </Text>
                    </VStack>

                    <VStack alignItems={"flex-start"} marginLeft={{base: "0rem", lg: "5rem"}}>
                        <HStack justifyContent={"left"} mt={12} >
                            <Stack bg={COLORS.ORANGE} height={50} width={50} borderRadius={25}>
                                <Center>
                                    <Image src={"/images/certifications_icon.svg"} verticalAlign={"middle"} height={35} width={35} mt={1.5}/>
                                </Center>
                            </Stack>

                            <Text fontSize={"xl"} color={COLORS.ORANGE}>
                                Certifications
                            </Text>
                        </HStack>

                        <a href={"https://bit.ly/3SO9Qnm"} target={"_blank"}>
                            <HStack justifyContent={"left"} mt={4}>
                                <Stack borderRadius={25}>
                                    <Center>
                                        <Image height={50} width={50} src={"/images/deep_learning_logo.png"} verticalAlign={"middle"} mt={1.5}/>
                                    </Center>
                                </Stack>

                                <Text fontSize={"xl"} color={COLORS.BLACK_FONT} mt={1.5} fontWeight={"bold"}>
                                    Deep Learning
                                </Text>
                            </HStack>

                            <Text fontSize={"md"} color={COLORS.BLACK_FONT} ml={57} fontWeight={"600"}>
                                <List>
                                    <ListItem>Certified by DeepLearning.ai</ListItem>
                                    <ListItem>Issued in September 2022</ListItem>
                                    <ListItem>Click to show the certificate</ListItem>
                                </List>
                            </Text>
                        </a>
                        <a href={"https://bit.ly/3twsUiQ"} target={"_blank"}>
                            <HStack justifyContent={"left"} mt={4}>
                                <Stack borderRadius={25}>
                                    <Center>
                                        <Image height={50} width={50} src={"/images/google_cloud_logo.png"} verticalAlign={"middle"} mt={1.5}/>
                                    </Center>
                                </Stack>

                                <Text fontSize={"xl"} color={COLORS.BLACK_FONT} mt={1.5} fontWeight={"bold"}>
                                    Digital Cloud Leader
                                </Text>
                            </HStack>

                            <Text fontSize={"md"} color={COLORS.BLACK_FONT} ml={57} fontWeight={"600"}>
                                <List>
                                    <ListItem>Certified by Google Cloud</ListItem>
                                    <ListItem>Issued in October 2023</ListItem>
                                    <ListItem>Click to show the certificate</ListItem>
                                </List>
                            </Text>
                        </a>
                    </VStack>
                </Stack>
            </Stack>
    )

}