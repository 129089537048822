import {
    Box,
    Card,
    CardBody,
    CardFooter,
    CardHeader,
    Center,
    HStack,
    Image,
    Stack,
    Text, Tooltip, VisuallyHidden,
    VStack
} from "@chakra-ui/react";
import {COLORS} from "../../core/constants";


export default function Portfolio() {


    return (
        <VStack bg={COLORS.LIGHT_GREY} id={"portfolio"}>
            <Text color={COLORS.BLACK_FONT} fontSize={{base: "2xl", md:"3xl"}}>Portfolio</Text>
            <Text color={COLORS.ORANGE} fontSize={{base: "2xl", md: "4xl"}}>RECENT PROJECTS</Text>

            <Center>
        <Stack spacing={8} mt={8} mb={12} direction={{base: "column", md: "row"}}>
            <a href={"https://apple.co/3bJ5XjL"} target={"_blank"}>
            <Card maxWidth={350} height={"38rem"}>
                <CardHeader padding={0}>
                <Box position="relative" display="inline-block">
                    <Image src={"/images/belal_icon.png"} alt="Belal Icon" borderTopRadius={"0.375rem"}/>
                    <Text
                        position="absolute"
                        top="90%"
                        left="50%"
                        transform="translate(-50%, -50%)"
                        color={COLORS.WHITE}
                        fontSize="18px"
                        fontWeight="bold"
                    >
                        Belal
                    </Text>
                </Box>
                </CardHeader>
                <CardBody>
                    <Text>
                        "Belal for Prayer Times": Your straightforward companion for accurate prayer times
                        and Qibla direction. Crafted with Swift & SwiftUI, the app embodies simplicity
                        and user-centered design. Belal your companion to pray on time.
                    </Text>
                </CardBody>
                <CardFooter>
                    <HStack>
                        <Tooltip label={"Swift"}>
                            <Image src={"/images/swift_logo.png"} maxWidth={"2rem"} alt="Swift"/>
                        </Tooltip>
                        <VisuallyHidden>Swift Logo</VisuallyHidden>
                        <Tooltip label={"Swift UI"}>
                            <Image src={"/images/swiftui_logo.png"} maxWidth={"2rem"} alt="Swift UI"/>
                        </Tooltip>
                        <VisuallyHidden>Swift UI Logo</VisuallyHidden>

                    </HStack>

                </CardFooter>
            </Card>
            </a>

            <a href={"/"}>
            <Card maxWidth={350} height={"38rem"}>
                <CardHeader padding={0}>
                    <Box position="relative" display="inline-block">
                        <Image src={"/images/self.png"} alt="Personal Website Logo" borderTopRadius={"0.375rem"}/>
                        <Text
                            position="absolute"
                            top="90%"
                            left="50%"
                            transform="translate(-50%, -50%)"
                            color={COLORS.WHITE}
                            fontSize="18px"
                            fontWeight="bold"
                        >
                            My Web Page
                        </Text>
                    </Box>
                </CardHeader>
                <CardBody>
                    <Text>
                        Explore my world—React TypeScript & Chakra UI bring my website to life.
                        Warning: Clicking may lead to a delightful loop of nerdy humor. Enjoy the ride!
                    </Text>
                </CardBody>
                <CardFooter>
                    <HStack>
                        <Tooltip label={"React"}>
                            <Image src={"/images/react-icon.svg"} width={"32px"} height={"32px"} alt={"React"}/>
                        </Tooltip>
                        <VisuallyHidden>React Logo</VisuallyHidden>
                        <Tooltip label={"Chakra UI"}>
                            <Image src={"/images/chakra.png"} width={"32px"} height={"32px"} alt="Chakra UI"/>
                        </Tooltip>
                        <VisuallyHidden>Chakra UI Logo</VisuallyHidden>
                        <Tooltip label={"Google Cloud"}>
                            <Image src={"/images/google_cloud_logo.png"} width={"32px"} height={"32px"} alt="Chakra UI"/>
                        </Tooltip>
                        <VisuallyHidden>Google Cloud Logo</VisuallyHidden>
                    </HStack>

                </CardFooter>
            </Card>
            </a>

        </Stack>
        </Center>
        </VStack>
    )
}