import {
    Button,
    Center,
    FormControl,
    FormLabel,
    HStack,
    Image,
    Input, List, ListItem,
    Stack,
    Text,
    Textarea, useToast,
    VStack
} from "@chakra-ui/react";
import {COLORS} from "../../core/constants";
import {Form} from "react-router-dom";
import {useState} from "react";


export default function Contact() {

    const [name, setName] = useState('')
    const [email, setEmail] = useState('');
    const [message, setMessage] = useState('')
    const [isSending, setIsSending] = useState(false)
    const toast = useToast()


    const handleNameChange = (event: any) => {
        setName(event.target.value);
    };
    const handleEmailChange = (event: any) => {
        setEmail(event.target.value);
    };

    const handleMessageChange = (event: any) => {
        setMessage(event.target.value);
    };

    const handleSubmit = async () => {

        if (name.length < 3) {
            toast({
                title: 'Name must be 3 characters or more.',
                position: "top",
                status: 'error'
            })
            return;
        }

        if (!isValidEmail(email)) {

            toast({
                title: 'Invalid email format.',
                position: "top",
                status: 'error'
            })
            return;
        }

        if (message.length < 50) {
            toast({
                title: 'Message must be 50 characters or more.',
                position: "top",
                status: 'error'
            })
            return;
        }

        try {

            console.log(JSON.stringify({
                name: name,
                email: email,
                message: message
            }))

            setIsSending(true)

            toast({
                title: 'Your message on its way! 🥳',
                status: 'info',
                position: "top",
                isClosable: true
            })

            const response = await fetch('https://europe-west2-amiable-mix-393114.cloudfunctions.net/contact-form', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Accept': '*/*'
                },
                body: JSON.stringify({
                    name: name,
                    email: email,
                    message: message
                }),
            });

            setIsSending(false)

            if (response.ok)  {
                setName("")
                setEmail("")
                setMessage("")
                toast({
                    title: 'Message sent successfully 🎉',
                    status: 'success',
                    position: "top",
                    isClosable: true
                })
            } else {
                toast({
                    title: 'Something went wrong! Please try again later. ☹️',
                    status: 'error',
                    position: "top",
                    isClosable: true
                })
            }
        } catch (error) {
            setIsSending(false)
            toast({
                title: 'Something went wrong! Please try again later. ☹️',
                status: 'error',
                position: "top",
                isClosable: true
            })
        }
    };

    const isValidEmail = (value: string) => {
        // Simple email validation using a regular expression
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return emailRegex.test(value);
    };

    return (
        <>
        <VStack bg={COLORS.LIGHT_GREY} paddingTop={"1rem"} id={"contact"}>
            <Text fontSize={"3xl"} color={COLORS.ORANGE}>Contact</Text>
            <Text fontSize={"4xl"} color={COLORS.BLACK_FONT}>Let Me Hear from You!</Text>
        </VStack>
            <Stack bg={COLORS.LIGHT_GREY} paddingLeft={"7%"} paddingRight={"7%"}
                    width={"100%"} spacing={{base: "2rem", lg: "15rem"}} paddingBottom={"2rem"} paddingTop={"2rem"}
                   direction={{base: "column", lg: "row"}}>
                <VStack alignItems={"flex-start"}>
                    <a href={"mailto:contact@aalharabi.sa"}>
                    <HStack justifyContent={"left"}>
                        <Stack bg={COLORS.ORANGE} height={50} width={50} borderRadius={25}>
                            <Center>
                                <Image src={"/images/email_icon.png"} verticalAlign={"middle"} height={35} width={35} mt={1.5}/>
                            </Center>
                        </Stack>

                        <Text fontSize={"xl"} color={COLORS.ORANGE}>
                            Email
                            <List>
                                <ListItem color={COLORS.BLACK_FONT}>contact@aalharabi.sa</ListItem>
                            </List>
                        </Text>
                    </HStack>
                    </a>

                    <a href={"https://wa.me/966551966172"} target={"_blank"}>
                    <HStack justifyContent={"left"} mt={"2rem"} >
                        <Stack bg={COLORS.ORANGE} height={50} width={50} borderRadius={25}>
                            <Center>
                                <Image src={"/images/whatsapp_icon.svg"} verticalAlign={"middle"} height={35} width={35} mt={1.5}/>
                            </Center>
                        </Stack>
                        <VStack>
                            <Text fontSize={"xl"} color={COLORS.ORANGE}>
                                Whatsapp
                                <List>
                                    <ListItem color={COLORS.BLACK_FONT}>+966551966172</ListItem>
                                </List>
                            </Text>

                        </VStack>
                    </HStack>
                    </a>
                </VStack>

                <VStack width={"100%"} alignItems={"flex-start"} spacing={"1rem"} color={COLORS.BLACK_FONT}>

                    <FormControl>
                        <FormLabel>Name</FormLabel>
                        <Input
                            type={"text"}
                            borderColor={COLORS.ORANGE}
                            borderWidth={"0.15rem"}
                            placeholder={"e.g. Abdulelah Alharabi"}
                            value={name}
                            onChange={handleNameChange}
                        />
                    </FormControl>

                        <FormControl>
                            <FormLabel>Email</FormLabel>
                            <Input
                                type={"email"}
                                borderColor={COLORS.ORANGE}
                                borderWidth={"0.15rem"}
                                placeholder={"e.g. hello@aalharabi.sa"}
                                value={email}
                                onChange={handleEmailChange}
                                pattern="[^\s@]+@[^\s@]+\.[^\s@]+"/>
                        </FormControl>

                    <FormControl>
                        <FormLabel>Message</FormLabel>
                        <Textarea
                            borderColor={COLORS.ORANGE}
                            borderWidth={"0.15rem"}
                            resize={"none"}
                            placeholder={"Hello, World!"}
                            minHeight={"10rem"}
                            value={message}
                            onChange={handleMessageChange}
                        ></Textarea>
                    </FormControl>

                    <Button bg={COLORS.ORANGE} fontSize={"md"} color={COLORS.WHITE} mt={"1rem"}
                            onClick={handleSubmit} isLoading={isSending} _hover={{bg: COLORS.ORANGE}}>Submit</Button>


                </VStack>
            </Stack>
        </>
    )

}